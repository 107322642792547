<template>
  <v-container fluid>
    <h2 class="d-flex align-center font-weight-medium mb-3 mt-1">
      {{ timeGreetings }}
      <span class="text--primary font-weight-black ml-2">{{ loggedInUser.firstName }}!</span>
    </h2>
    <div v-if="$has(perms.ExternalDashboard.ViewAllWidgets)" key="has-widget-perms">
      External Widgets
    </div>
    <div
      v-else
      key="no-widget-perms"
      class="d-flex flex-column align-center justify-center"
      style="width: 100%; height: 100%; min-height: 64vh"
    >
      <div class="area">
        <ul class="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
      <span class="main-dash-icon-wrapper">
        <i
          class="fad fa-planet-ringed opacity-54 floating main-dash-icon"
          style="font-size: 12rem"
        ></i>
      </span>
      <h2 class="mt-6 font-weight-black opacity-72 fs-22px">Welcome to Concordia!</h2>
      <p class="mt-1 font-weight-medium opacity-54 fs-16px">
        You don't have available widgets to view yet!
      </p>
    </div>
  </v-container>
</template>

<script>
import perms from "../../../../plugins/permissions";

export default {
  components: {},
  data() {
    return {
      perms: perms,
      hourNow: 0,
      rand: 1,
    };
  },
  created() {
    this.rand = Math.random() > 0.5 ? 1 : 0;
  },
  mounted() {
    this.updateNow();
    setInterval(this.updateNow.bind(this), 1000);
  },
  methods: {
    updateNow() {
      this.hourNow = new Date().getHours();
    },
  },
  computed: {
    dashboardGreetings() {
      return "Welcome Back,";
    },
    timeGreetings() {
      if (this.hourNow > 18) {
        return "Good evening,";
      } else if (this.hourNow > 12) {
        return "Good afternoon,";
      } else if (this.hourNow > 0) {
        return "Good morning,";
      } else {
        return "Welcome Back,";
      }
    },
  },
  watch: {},
};
</script>

<style lang="scss">
.floating {
  animation-name: floating;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floating {
  from {
    transform: translate(0, 0px);
  }
  64% {
    transform: translate(0, 30px);
  }
  to {
    transform: translate(0, -0px);
  }
}
.area {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 0;
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba($shades-black, 0.08);
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 5%;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}
</style>
